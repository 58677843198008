import React, { useState, useEffect, useCallback } from 'react';
import AxiosInstance from '../Axios';
import { useAuth } from '../services/AuthContext';
import { createPurchaseRequest, editPurchaseRequest, deletePurchaseRequest } from '../services/PurchaseRequestService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../services/ToastService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../services/Loader';

const PurchaseRequestList = () => {
  const { currentUser } = useAuth();
  const [EditedPurchaseRequest, setEditedPurchaseRequest] = useState(null);
  const [PurchaseRequest, setPurchaseRequest] = useState([]);
  const [ApprovedPurchaseRequest, setApprovedPurchaseRequest] = useState([]);
  const [fundName, setFundName] = useState([]);
  const [showFundRequest, setshowFundRequest] = useState(false);
  const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [remainingFund, setRemainingFund] = useState(0);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
  const [requestedBy, setRequestedBy] = useState('');


  const handleDeleteItem = (index) => {
    const updatedItems = [...newPurchase.items];
    updatedItems.splice(index, 1);
    setNewPurchase({ ...newPurchase, items: updatedItems });
  };

  const [newPurchase, setNewPurchase] = useState({
    voucher_no: 'PR-1',
    activity: '',
    profit_center: '',
    covered_from: '',
    covered_to: '',
    date_requested: '',
    encoded_date: '',
    items: [{ descriptions: '', quantity: '', uom: '', price: '', item_total_amount: '' }],
    amount: '',
    business_unit: [],
    requested_by: '',
    fund_allocation: '',
    status: '',
  });

  const calculateAmount = () => {
    let totalAmount = 0;

    newPurchase.items.forEach((item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.price) || 0;
      totalAmount += quantity * price;
    });

    return isNaN(totalAmount) ? 0 : totalAmount;
  };


  const calculateEditedAmount = () => {
    let totalAmount = 0;

    EditedPurchaseRequest.items.forEach((edititem) => {
      const quantity = parseFloat(edititem.quantity) || 0;
      const price = parseFloat(edititem.price) || 0;
      totalAmount += quantity * price;
    });
    return isNaN(totalAmount) ? 0 : totalAmount;
  };


  // Fetch and filter business units
  const fetchAndFilterBusinessUnits = useCallback(async () => {
    try {
      const fundResponse = await AxiosInstance.get('/api/Allocation_List_Per_BU_View/');
      const allBusinessUnits = fundResponse.data;
      const currentUserBusinessUnit = currentUser.business_unit;
      const filteredBusinessUnits = allBusinessUnits.filter(businessUnit => businessUnit.business_unit_name === currentUserBusinessUnit);
      setFilteredBusinessUnits(filteredBusinessUnits);

      if (filteredBusinessUnits.length === 0) {
        console.error("Business unit not found for current user");
      }

      setFundName(filteredBusinessUnits);

      if (!currentUser) {
        toast.error('Current user not Found.');
        return;
      }
    } catch (error) {
      console.error("Error fetching and filtering business units:", error);
    }
  }, [currentUser]);

  // Fetch purchase requests
  const fetchAndFilterPurchaseRequests = useCallback(async () => {
    try {
      const currentUserBusinessUnit = currentUser.business_unit;
      const response = await AxiosInstance.get('/api/PurchaseRequestListView/');
      const purchaseRequests = response.data;
      const filteredPurchaseRequests = purchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
      setPurchaseRequest(filteredPurchaseRequests);
    } catch (error) {
      console.error("Error fetching and filtering purchase requests:", error);
    }
  }, [currentUser]);

  // Fetch approved purchase requests
  const fetchAndFilterApprovedPurchaseRequests = useCallback(async () => {
    try {
      const currentUserBusinessUnit = currentUser.business_unit;
      const approvedResponse = await AxiosInstance.get('/api/PurchaseRequestApprovedListView/');
      const approvedPurchaseRequests = approvedResponse.data;
      const filteredApprovedPurchaseRequests = approvedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
      setApprovedPurchaseRequest(filteredApprovedPurchaseRequests);
    } catch (error) {
      console.error("Error fetching and filtering approved purchase requests:", error);
    }
  }, [currentUser]);

  // Fetch latest voucher number
  const fetchAndSetNewVoucherNumber = useCallback(async () => {
    try {
      const lastestresponse = await AxiosInstance.get('/api/latest_voucher/');
      const latestVoucher = lastestresponse.data.vouchers;
      const businessUnit = currentUser.business_unit || null;

      if (latestVoucher && latestVoucher.length > 0) {
        const userVouchers = latestVoucher.filter(voucher => voucher.business_unit.name === businessUnit);

        if (userVouchers.length > 0) {
          const latestVoucherNumber = Math.max(...userVouchers.map(voucher => parseInt(voucher.voucher_number.slice(3), 10)));
          const newVoucherNo = `PR-${latestVoucherNumber + 1}`;

          setNewPurchase(prevPurchase => ({
            ...prevPurchase,
            voucher_no: newVoucherNo,
          }));
        } else {
          console.log('No vouchers available for the current user business unit');
        }
      } else {
        console.log('No vouchers available');
      }
    } catch (error) {
      console.error("Error fetching and setting new voucher number:", error);
    }
  }, [currentUser]);


  useEffect(() => {
    const fetchData = async () => {
      try {

        await fetchAndFilterPurchaseRequests();

        await fetchAndFilterApprovedPurchaseRequests();

        await fetchAndFilterBusinessUnits();

        await fetchAndSetNewVoucherNumber();


        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data from server.', type: 'error' });
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser, fetchAndFilterPurchaseRequests, fetchAndFilterApprovedPurchaseRequests, fetchAndFilterBusinessUnits, fetchAndSetNewVoucherNumber]);


  const totalAmount = ApprovedPurchaseRequest.reduce((total, request) => {
    return total + parseFloat(request.amount);
  }, 0);



  useEffect(() => {

    const totalAmount = ApprovedPurchaseRequest.reduce((total, request) => {
      return total + parseFloat(request.amount);
    }, 0);


    fundName.forEach((buData) => {
      const allocatedAmount = parseFloat(buData.allocated_amount || 0);
      const remainingAmount = allocatedAmount - totalAmount;
      setRemainingFund((prevRemainingFund) => ({
        ...prevRemainingFund,
        [buData.business_unit_name]: remainingAmount,
      }));
    });
  }, [ApprovedPurchaseRequest, fundName,]);


  if (loading) {
    return <Loader />;
  }

  const resetForm = () => {
    setNewPurchase({
      activity: '',
      profit_center: '',
      covered_from: '',
      covered_to: '',
      date_requested: '',
      encoded_date: '',
      items: [{ descriptions: '', quantity: '', uom: '', price: '', item_total_amount: '' }],
      amount: '',
      business_unit: '',
      requested_by: '',
      fund_allocation: '',
      status: '',
    });
  };


  const handleCreatePurchaseRequest = async () => {
    try {

      if (!requestedBy) {
        toast.error('Requested By is required.');
        return;
      }

      const userId = currentUser.id || null;
      const selectedFundAllocation = fundName[0];

      if (!selectedFundAllocation) {
        toast.error('No fund allocation selected.');
        return;
      }

      const totalAmount = calculateAmount();
      const remainingFunds = Object.values(remainingFund).reduce((acc, curr) => acc + curr, 0);

      if (totalAmount > remainingFunds) {
        toast.error('Requested amount exceeds remaining funds.');
        return;
      }

      const invalidItem = newPurchase.items.find(item =>
        !item.descriptions ||
        !item.quantity ||
        isNaN(item.quantity) ||
        !item.uom ||
        !item.price ||
        isNaN(item.price)
      );

      if (invalidItem) {
        toast.error('All item fields must be filled. Quantity and Price should be numeric.');
        return;
      }

      const requiredFields = ['activity', 'profit_center', 'covered_from', 'covered_to'];
      for (const field of requiredFields) {
        if (!newPurchase[field]) {
          toast.error(`${field.replace('_', ' ')} is required.`);
          return;
        }
      }

      const currentDate = new Date();
      const isoDateString = currentDate.toISOString().split('T')[0];

      const businessUnitId = filteredBusinessUnits.length > 0 ? filteredBusinessUnits[0].business_unit_id : null;

      const requestData = {
        ...newPurchase,
        requested_by: requestedBy,
        reviewed_date: isoDateString,
        reviewed_by: userId,
        business_unit: businessUnitId,
        fund_allocation: selectedFundAllocation.id,
        amount: calculateAmount().toFixed(2),
        status: 'Approved',
        date_requested: isoDateString,
        encoded_date: isoDateString,
        items: newPurchase.items.map((item) => ({
          descriptions: item.descriptions || '',
          quantity: item.quantity || 0,
          uom: item.uom || '',
          price: parseFloat(item.price).toFixed(2) || 0,
          item_total_amount: ((parseFloat(item.quantity) || 0) * parseFloat(item.price || 0)).toFixed(2),
        })),
      };

      await createPurchaseRequest(requestData);
      await fetchAndFilterBusinessUnits();
      await fetchAndFilterPurchaseRequests();
      await fetchAndSetNewVoucherNumber();

      resetForm();

      window.location.reload();

      Toast({ message: 'Fund request created successfully:', type: 'success' });
    } catch (error) {
      Toast({ message: 'Error creating fund request.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server Error Response:', type: 'error' });
      }
    }
  };


  const handleEditPurchaseRequest = async () => {
    try {

      if (!currentUser) {
        toast.error('Current Fund Request not available.');
        return;
      }

      if (!EditedPurchaseRequest) {
        toast.error('No Fund Request data to edit.');
        return;
      }

      const userId = currentUser.id || null;
      const businessUnitId = filteredBusinessUnits.length > 0 ? filteredBusinessUnits[0].business_unit_id : null;
      const selectedFundAllocation = fundName[0];

      if (!selectedFundAllocation) {
        toast.error('No fund allocation selected.');
        return;
      }

      const totalAmount = calculateEditedAmount();
      const remainingFunds = Object.values(remainingFund).reduce((acc, curr) => acc + curr, 0);

      if (totalAmount > remainingFunds) {
        toast.error('Requested amount exceeds remaining funds.');
        return;
      }

      const editedItems = (EditedPurchaseRequest.items || []).map((edititem) => ({
        descriptions: edititem.descriptions || '',
        quantity: edititem.quantity || 0,
        uom: edititem.uom || '',
        price: parseFloat(edititem.price).toFixed(2) || 0,
        item_total_amount: ((parseFloat(edititem.quantity) || 0) * parseFloat(edititem.price || 0)).toFixed(2),
      }));

      const editedData = {
        id: EditedPurchaseRequest.id,
        ...EditedPurchaseRequest,
        requested_by: EditedPurchaseRequest.requested_by,
        reviewed_by: userId,
        business_unit: businessUnitId,
        fund_allocation: selectedFundAllocation.id,
        amount: (calculateEditedAmount(EditedPurchaseRequest.amount)).toFixed(2),
        status: 'Approved',
        items: editedItems,
      };

      const response = await editPurchaseRequest(editedData.id, editedData);
      setEditedPurchaseRequest(response.data);

      await fetchAndFilterBusinessUnits();
      await fetchAndFilterPurchaseRequests();

      setIsEditModalOpen(false);

      Toast({ message: 'Fund Request edited successfully.', type: 'success' });

    } catch (error) {
      Toast({ message: 'Error editing Fund Request', type: 'error' });

      if (error.response) {
        Toast({ message: 'Server response data.', type: 'error' });
      }
    }
  };



  const handleDelete = async (purchaseRequestId) => {
    try {

      const isConfirmed = window.confirm('Are you sure you want to delete this Fund Request?');
      if (isConfirmed) {

        await deletePurchaseRequest(purchaseRequestId);
        await fetchAndFilterBusinessUnits();
        await fetchAndFilterPurchaseRequests();

        Toast({ message: 'Fund Request deleted successfully.', type: 'success' });
      } else {
        Toast({ message: 'Fund Request deleted cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting Fund Request:', type: 'error' });
    }
  };


  const handleEdit = (purchaseRequestId) => {
    const purchaseRequestToEdit = PurchaseRequest.find((purchaseRequest) => purchaseRequest.id === purchaseRequestId);
    setEditedPurchaseRequest({
      ...purchaseRequestToEdit,
      items: purchaseRequestToEdit.items.map((edititem) => ({ ...edititem })),
    });
    setIsEditModalOpen(true);
  };


  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditedPurchaseRequest(null);
  };


  function formatAmountWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleItemChange = (index, field, value) => {
    setNewPurchase((prevPurchase) => {
      const updatedItems = [...prevPurchase.items];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
      return { ...prevPurchase, items: updatedItems };
    });
  };

  const handleEditItemChange = (index, field, value) => {
    setEditedPurchaseRequest((prevPurchase) => {
      const updatedItems = [...prevPurchase.items];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
      return { ...prevPurchase, items: updatedItems };
    });
  };

  const handleAddItem = () => {
    setNewPurchase((prevPurchase) => ({
      ...prevPurchase,
      items: [...prevPurchase.items, { description: '', quantity: '', uom: '', price: '', item_total_amount: '' }],
    }));
  };

  const handleFundRequest = async () => {
    setshowFundRequest(true);
  };
  const handleToggleFormClose = async () => {
    setshowFundRequest(false);
  };

  const handleViewDetail = (purchaseRequest) => {
    setSelectedPurchaseRequest(purchaseRequest);
  };

  const handleClosePopup = () => {
    setSelectedPurchaseRequest(null);
  };

  function formatDate(dateString) {
    const dateParts = dateString.split("-");
    const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return formattedDate.toLocaleDateString("en-US");
  }


  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [205, 210]
    });
    pdf.setFont('times', 'normal');
    pdf.text(` ${selectedPurchaseRequest.business_unit_name} Fund Request Details`, 70, 10);
    pdf.setFontSize(8);
    pdf.setFontSize(10);

    pdf.setFont('times', 'normal');
    pdf.text(`Voucher No: ${selectedPurchaseRequest.voucher_no}`, 20, 30);
    pdf.text(`Activity: ${selectedPurchaseRequest.activity}`, 20, 35);
    pdf.text(`Profit Center: ${selectedPurchaseRequest.profit_center}`, 20, 40);
    pdf.text(`Covered Date: ${formatDate(selectedPurchaseRequest.covered_from)} - ${formatDate(selectedPurchaseRequest.covered_to)}`, 20, 45);
    pdf.text(`Status: ${selectedPurchaseRequest.status}`, 20, 50);

    const dateRequestedText = `Date Requested: ${formatDate(selectedPurchaseRequest.date_requested)}`;
    const dateRequestedWidth = pdf.getTextDimensions(dateRequestedText).w;
    const dateRequestedX = pdf.internal.pageSize.width - 20 - dateRequestedWidth;
    pdf.text(dateRequestedText, dateRequestedX, 30);

    const items = selectedPurchaseRequest.items;
    const headers = ['Description', 'Quantity', 'Unit of Measure', 'Price', 'Amount'];
    const data = items.map(item => [item.descriptions, formatAmountWithCommas(item.quantity), item.uom, formatAmountWithCommas(item.price), formatAmountWithCommas(item.item_total_amount)]);
    const totalAmountRow = ['', '', '', 'Total:', `${formatAmountWithCommas(selectedPurchaseRequest.amount)}`];
    data.push(totalAmountRow);

    let startY = 55;

    pdf.autoTable({
      head: [headers],
      body: data,
      startY: startY,
      styles: {
        fontSize: 8
      },
      didDrawPage: function (data) {
        startY = data.cursor.y + 10;
      }
    });

    pdf.text(`Prepared By:`, 20, startY);
    pdf.text(`Date:`, 20, startY + 15);
    pdf.text(`${selectedPurchaseRequest.requested_by} `, 20, startY + 5);
    pdf.text(`Cost Controller`, 20, startY + 10);
    pdf.text(`${formatDate(selectedPurchaseRequest.encoded_date)} `, 30, startY + 15);


    pdf.text(`Reviewed By:`, 90, startY);
    pdf.text(`Date:`, 90, startY + 15);
    pdf.text(`${selectedPurchaseRequest.reviewer_name}  ${selectedPurchaseRequest.reviewer_last}`, 90, startY + 5);
    pdf.text(`Cost Controller`, 90, startY + 10);
    pdf.text(`${formatDate(selectedPurchaseRequest.reviewed_date)} `, 100, startY + 15);

    if (selectedPurchaseRequest.approved) {
      pdf.text(`Approved By:`, 150, startY);
      pdf.text(`Date:`, 150, startY + 10);
      pdf.text(`${selectedPurchaseRequest.approved}  ${selectedPurchaseRequest.approved_last}`, 150, startY + 5);
      pdf.text(`${formatDate(selectedPurchaseRequest.approved_date)} `, 160, startY + 10)
    }



    pdf.setFontSize(8);
    pdf.output('dataurlnewwindow');
  };



  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };



  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainer = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
    flexDirection: 'column',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#52ab98',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
  };


  const tabContainerStyle = {
    display: 'flex',
    marginBottom: '5px',
    marginLeft: '8px',
    background: '#EFF3F4',
  };

  const tabButtonStyle = {
    padding: '10px',
    marginRight: '5px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    borderBottom: activeTab === 1 ? '2px solid transparent' : '2px solid transparent',
  };


  return (
    <div>

      <div style={formContainerStyle}>
        <h4>
          <label htmlFor="RemainingFund">Available Fund: </label>
          {fundName.map((buData) => (
            <label key={buData.business_unit_name} style={{ display: 'block', marginBottom: '8px', fontWeight: 'normal' }}>
              {buData.business_unit_name} : ₱ {formatAmountWithCommas(parseFloat(buData.allocated_amount).toFixed(2))}
            </label>
          ))}
        </h4>
        {fundName.map((buData) => {
          const isAmountHigher = parseFloat(calculateAmount() || 0) > parseFloat(buData.allocated_amount || 0);
          return isAmountHigher && (
            <p key={buData.business_unit_name} style={{ color: 'red' }}>Amount is higher than the Remaining Fund</p>
          );
        })}

        <h4>
          <label htmlFor="RemainingFund">Reserved Fund: </label>
        </h4>
        {fundName.map((buData) => {
          const remainingAmount = totalAmount;

          return (
            <div key={buData.business_unit_name}>
              <p style={{ display: 'block', marginBottom: '8px', fontWeight: 'normal' }} > {buData.business_unit_name} : ₱ {formatAmountWithCommas(parseFloat(remainingAmount).toFixed(2))}</p>
            </div>
          );
        })}

        <div style={tabContainerStyle} className="tab-buttons">
          <button style={{ ...tabButtonStyle, background: '#52ab98', }} onClick={handleFundRequest} disabled={Object.values(remainingFund).some(amount => amount <= 0)} >
            Request Fund
          </button>
          <button onClick={() => handleTabClick(1)} style={{ ...tabButtonStyle, background: '#26577C', }} className={activeTab === 1 ? 'active' : ''}>
            Pending Fund Request
          </button>
          <button onClick={() => handleTabClick(2)} style={{ ...tabButtonStyle, background: '#F6995C', }} className={activeTab === 2 ? 'active' : ''}>
            Approved Fund Request
          </button>
        </div>
      </div>



      {showFundRequest && newPurchase && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '70%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <h2>Fund Request</h2>
            <form style={formContainerStyle}>
              <div style={flexContainer}>
                <div style={{ ...flexItemStyle, display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="createVoucherNo">Voucher No:</label>
                  <label id="createVoucherNo">
                    {newPurchase.voucher_no}
                  </label>
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="createActivity">Activity:</label>
                  <input
                    style={inputStyle}
                    type="text"
                    id="createActivity"
                    value={newPurchase.activity}
                    onChange={(e) => setNewPurchase({ ...newPurchase, activity: e.target.value })}
                  />
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="createProfitCenter">Profit Center:</label>
                  <input
                    style={inputStyle}
                    type="text"
                    id="createProfitCenter"
                    value={newPurchase.profit_center}
                    onChange={(e) => setNewPurchase({ ...newPurchase, profit_center: e.target.value })}
                  />
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="createCoveredDate">Covered Date:</label>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <label htmlFor="createCoveredFrom">From:</label>
                    <input
                      style={inputStyle}
                      type="date"
                      id="createCoveredFrom"
                      value={newPurchase.covered_from}
                      onChange={(e) => setNewPurchase({ ...newPurchase, covered_from: e.target.value })}
                    />
                    <label htmlFor="createCoveredTo">To:</label>
                    <input
                      style={inputStyle}
                      type="date"
                      id="createCoveredTo"
                      value={newPurchase.covered_to}
                      onChange={(e) => setNewPurchase({ ...newPurchase, covered_to: e.target.value })}
                    />
                  </div>
                </div>

              </div>

              <div style={flexContainerStyle}>
                {newPurchase.items.map((item, index) => (
                  <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`createDescription${index}`}>Description:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id={`createDescription${index}`}
                        value={item.descriptions || ''}
                        onChange={(e) => handleItemChange(index, 'descriptions', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`createQuantity${index}`}>Quantity:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`createQuantity${index}`}
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`createUnitofMeasure${index}`}>Unit of Measure:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id={`createUnitofMeasure${index}`}
                        value={item.uom}
                        onChange={(e) => handleItemChange(index, 'uom', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`createPrice${index}`}>Price:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`createPrice${index}`}
                        value={item.price}
                        onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`createTotal${index}`}>Amount:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`createTotal${index}`}
                        value={((parseFloat(item.quantity) || 0) * (parseFloat(item.price) || 0)).toFixed(2)}
                        onChange={(e) => handleItemChange(index, 'item_total_amount', e.target.value)}
                        readOnly
                      />
                    </div>

                    <div style={{ marginLeft: '8px', alignSelf: 'center', marginTop: "25px" }}>
                      <DeleteIcon style={{ color: '#9e9e9e', cursor: 'pointer' }} onClick={() => handleDeleteItem(index)} />
                    </div>
                  </div>
                ))}
              </div>


              <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '8px' }}>
                <button type="button" onClick={handleAddItem} style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', display: 'inline-block' }}>
                  Add Item
                </button>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="createAmount">Total Amount:</label>
                  <input
                    style={{ ...inputStyle, textAlign: 'right' }}
                    type="text"
                    id="createAmount"
                    value={(calculateAmount()).toFixed(2)}
                    onChange={(e) => setNewPurchase({ ...newPurchase, amount: e.target.value })}
                    disabled
                  />
                </div>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="createAmount">Prepared By:</label>
                  <input
                    type="text"
                    placeholder="Enter Name..."
                    value={requestedBy}
                    onChange={(e) => setRequestedBy(e.target.value || null)}
                    style={inputStyle}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button type="button" style={{ background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleCreatePurchaseRequest}>Save</button>
                <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleToggleFormClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {activeTab === 1 && (
        <div style={{ overflowX: 'auto' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                <th>No</th>
                <th>Voucher No</th>
                <th>Activity</th>
                <th>Profit Center</th>
                <th>Covered Date</th>
                <th>Date Requested</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {PurchaseRequest.map((purchaseRequest, index) => (
                <React.Fragment key={purchaseRequest.id}>
                  <tr style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>{purchaseRequest.voucher_no}</td>
                    <td style={{ textAlign: 'center' }}>{purchaseRequest.activity}</td>
                    <td style={{ textAlign: 'center' }}>{purchaseRequest.profit_center}</td>
                    <td style={{ textAlign: 'center' }}>{`${formatDate(purchaseRequest.covered_from)} to ${formatDate(purchaseRequest.covered_to)}`}</td>
                    <td style={{ textAlign: 'center' }}>{formatDate(purchaseRequest.date_requested)}</td>
                    <td style={{ textAlign: 'center' }}>{purchaseRequest.status}</td>
                    <td style={{ textAlign: 'center' }}>
                      <button
                        type="button"
                        style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', display: 'inline-block' }}
                        onClick={() => handleViewDetail(purchaseRequest)}
                      >
                        View
                      </button>
                      <button
                        type="button"
                        style={{ marginRight: '8px', background: '#3B71CA', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', display: 'inline-block' }}
                        onClick={() => handleEdit(purchaseRequest.id)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', display: 'inline-block' }}
                        onClick={() => handleDelete(purchaseRequest.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === 2 && (
        <div style={{ overflowX: 'auto' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                <th>No</th>
                <th>Voucher No</th>
                <th>Activity</th>
                <th>Profit Center</th>
                <th>Covered Date</th>
                <th>Date Requested</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {ApprovedPurchaseRequest.map((approvedPurchaseRequest, index) => (
                <React.Fragment key={approvedPurchaseRequest.id}>
                  <tr style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>{approvedPurchaseRequest.voucher_no}</td>
                    <td style={{ textAlign: 'center' }}>{approvedPurchaseRequest.activity}</td>
                    <td style={{ textAlign: 'center' }}>{approvedPurchaseRequest.profit_center}</td>
                    <td style={{ textAlign: 'center' }}>{`${formatDate(approvedPurchaseRequest.covered_from)} to ${formatDate(approvedPurchaseRequest.covered_to)}`}</td>
                    <td style={{ textAlign: 'center' }}>{formatDate(approvedPurchaseRequest.date_requested)}</td>
                    <td style={{ textAlign: 'center' }}>{approvedPurchaseRequest.status}</td>
                    <td style={{ textAlign: 'center' }}>
                      <button
                        type="button"
                        style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', display: 'inline-block' }}
                        onClick={() => handleViewDetail(approvedPurchaseRequest)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedPurchaseRequest && (
        <div>
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: '#fff',
                padding: '16px',
                borderRadius: '4px',
                width: '70%',
                overflow: 'auto',
                maxHeight: '80%',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginRight: '5px' }}>
                <IconButton
                  color="gray"
                  onClick={generatePDF}
                  sx={{ borderRadius: '4px', padding: '8px' }}
                >
                  <PrintIcon />
                </IconButton>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <strong>Voucher No:</strong> {selectedPurchaseRequest.voucher_no}
                </div>
                <div>
                  <strong>Date Requested:</strong> {formatDate(selectedPurchaseRequest.date_requested)}
                </div>
              </div>
              <div>
                <strong>Covered Date:</strong> {formatDate(selectedPurchaseRequest.covered_from)} to {formatDate(selectedPurchaseRequest.covered_to)}
              </div>
              <div>
                <strong>Activity:</strong> {selectedPurchaseRequest.activity}
              </div>
              <div>
                <strong>Profit Center:</strong> {selectedPurchaseRequest.profit_center}
              </div>
              <div>
                <strong>Company Name:</strong> {selectedPurchaseRequest.business_unit_name}
              </div>
              <div>
                <strong>Status:</strong> {selectedPurchaseRequest.status}
              </div>
              <div>
                <strong>Items:</strong>
              </div>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit of Measure</th>
                    <th>Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedPurchaseRequest.items.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: 'center' }}>{item.descriptions}</td>
                      <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.quantity)}</td>
                      <td style={{ textAlign: 'center' }}>{item.uom}</td>
                      <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.price)}</td>
                      <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.item_total_amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginRight: '40px' }}>
                <div>
                  <strong>Total Amount:</strong> {formatAmountWithCommas(selectedPurchaseRequest.amount)}
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginRight: '20px' }}>
                {selectedPurchaseRequest.requested_by ? (
                  <div><strong>Prepared By:</strong> {selectedPurchaseRequest.requested_by} </div>
                ) : (
                  <div><strong>Prepared By:</strong> {selectedPurchaseRequest.reviewer_name} {selectedPurchaseRequest.reviewer_last}</div>
                )}
                <div><strong>Reviewed By:</strong> {selectedPurchaseRequest.reviewer_name} {selectedPurchaseRequest.reviewer_last}</div>
                {selectedPurchaseRequest.approved ? (
                  <div><strong>Approved By:</strong> {selectedPurchaseRequest.approved} {selectedPurchaseRequest.approved_last}</div>
                ) : null}
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div><strong>Cost Controller</strong></div>
                <div><strong>Management Accounting Head</strong></div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '40px' }}>
                {selectedPurchaseRequest.encoded_date ? (
                  <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.encoded_date)} </div>
                ) : (
                  <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.reviewed_date)} </div>
                )}
                <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.reviewed_date)} </div>
                {selectedPurchaseRequest.approved_date ? (
                  <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.approved_date)} </div>
                ) : null}
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px', marginLeft: '10px' }} onClick={handleClosePopup}>Close</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && EditedPurchaseRequest && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '70%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <form style={formContainerStyle}>
              <div style={flexContainer}>
                <div style={{ ...flexItemStyle, display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="editVoucherNo">Voucher No:</label>
                  <label style={inputStyle} id="editVoucherNo">
                    {EditedPurchaseRequest.voucher_no}
                  </label>
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="editActivity">Activity:</label>
                  <input
                    style={inputStyle}
                    type="text"
                    id="editActivity"
                    value={EditedPurchaseRequest.activity || ''}
                    onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, activity: e.target.value })}
                  />
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="editProfitCenter">Profit Center:</label>
                  <input
                    style={inputStyle}
                    type="text"
                    id="editProfitCenter"
                    value={EditedPurchaseRequest.profit_center}
                    onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, profit_center: e.target.value })}
                  />
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="editCoveredDate">Covered Date:</label>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <label htmlFor="editCoveredFrom">From:</label>
                    <input
                      style={inputStyle}
                      type="date"
                      id="editCoveredFrom"
                      value={EditedPurchaseRequest.covered_from}
                      onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, covered_from: e.target.value })}
                    />
                    <label htmlFor="editCoveredTo">To:</label>
                    <input
                      style={inputStyle}
                      type="date"
                      id="editCoveredTo"
                      value={EditedPurchaseRequest.covered_to}
                      onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, covered_to: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div style={flexContainerStyle}>
                {EditedPurchaseRequest.items.map((edititem, index) => (
                  <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`editDescription${index}`}>Description:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id={`editDescription${index}`}
                        value={edititem.descriptions}
                        onChange={(e) => handleEditItemChange(index, 'descriptions', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`editQuantity${index}`}>Quantity:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`editQuantity${index}`}
                        value={edititem.quantity}
                        onChange={(e) => handleEditItemChange(index, 'quantity', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`editUnitofMeasure${index}`}>Unit of Measure:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id={`editUnitofMeasure${index}`}
                        value={edititem.uom}
                        onChange={(e) => handleEditItemChange(index, 'uom', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`editPrice${index}`}>Price:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`editPrice${index}`}
                        value={edititem.price}
                        onChange={(e) => handleEditItemChange(index, 'price', e.target.value)}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor={`editTotal${index}`}>Amount:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id={`editTotal${index}`}
                        value={((parseFloat(edititem.quantity) || 0) * (parseFloat(edititem.price) || 0)).toFixed(2)}
                        onChange={(e) => handleEditItemChange(index, 'item_total_amount', e.target.value)}
                        readOnly
                      />
                    </div>

                  </div>
                ))}
              </div>


              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="editAmount">Total Amount:</label>
                  <input
                    style={{ ...inputStyle, textAlign: 'right' }}
                    type="text"
                    id="editAmount"
                    value={(calculateEditedAmount(EditedPurchaseRequest.items)).toFixed(2)}
                    onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, amount: e.target.value })}
                    disabled
                  />
                </div>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="EditRequestedBy">Prepared By:</label>
                  <input
                    type="text"
                    value={EditedPurchaseRequest.requested_by || ''}
                    onChange={(e) => setEditedPurchaseRequest({ ...EditedPurchaseRequest, requested_by: e.target.value })}
                    style={inputStyle}
                  />
                </div>
              </div>


              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button style={buttonStyle} type="button" onClick={handleEditPurchaseRequest}>
                  Save
                </button>
                <button
                  style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginLeft: '5px' }}
                  onClick={handleCloseEditModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>


  );
};

export default PurchaseRequestList;
