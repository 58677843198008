import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { useAuth } from '../services/AuthContext';
import { useNavigate } from 'react-router-dom';
import Toast from '../services/ToastService';
import { ReplenishPurchaseRequest } from '../services/ApprovedRequesteService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Loader from '../services/Loader';
const LiquidatedCard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [PurchaseRequest, setPurchaseRequest] = useState([]);
  const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [selectedDateFilter, setSelectedDateFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tobereleaseresponse = await AxiosInstance.get('/api/Cost_Controller_Liquidated_View/');
        setPurchaseRequest(tobereleaseresponse.data);
        setLoading(false);

      } catch (error) {
        Toast({ message: 'Error fetching data from server.', type: 'error' });
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  if (loading) {
    return <Loader />;
  }


  const handleReplenish = async () => {
    try {
      const currentDate = new Date().toISOString().slice(0, 10);

      if (selectedPurchaseRequest) {
        const approvedData = {
          ...selectedPurchaseRequest,
          status: 'Replenished',
          replenish_date: currentDate,
        };

        if (typeof approvedData.fund_allocation === 'object' && approvedData.fund_allocation !== null) {
          approvedData.fund_allocation.amount += approvedData.amount;

        }

        await ReplenishPurchaseRequest(approvedData.id, approvedData);

        const updatedResponse = await AxiosInstance.get('/api/Cost_Controller_Liquidated_View/');
        setPurchaseRequest(updatedResponse.data);

        Toast({ message: 'Fund Request Replenished successfully.', type: 'success' });
        setSelectedPurchaseRequest(null);
      } else {
        Toast({ message: 'Fund Request not found.', type: 'error' });
      }
    } catch (error) {
      Toast({ message: 'Error Replenishing Fund Request.', type: 'error' });
    }
  };


  const handleViewDetail = (purchaseRequest) => {
    setSelectedPurchaseRequest(purchaseRequest);
  };


  const handleClosePopup = () => {
    setSelectedPurchaseRequest(null);
  };


  const navigateToDashboard = () => {
    navigate('/CustodianDashboard');
  };


  const currentDate = new Date().toISOString().split('T')[0];
  const dateOptions = ['All', 'Today', 'Yesterday'];

  const handleDateFilterChange = (event) => {
    setSelectedDateFilter(event.target.value);
  };

  const filteredPurchaseRequest = () => {
    switch (selectedDateFilter) {
      case 'Today':
        return PurchaseRequest.filter(item => item.date_requested === currentDate);
      case 'Yesterday':
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedYesterday = yesterday.toISOString().split('T')[0];
        return PurchaseRequest.filter(item => item.date_requested === formattedYesterday);
      default:
        return PurchaseRequest;
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPurchaseRequest().slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(PurchaseRequest.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  function formatDate(dateString) {
    const dateParts = dateString.split("-");
    const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return formattedDate.toLocaleDateString("en-US");
  }

  function formatAmountWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [205, 210]
    });
    pdf.setFont('times', 'normal');
    pdf.text(` ${selectedPurchaseRequest.business_unit_name} Fund Request Details`, 60, 10);
    pdf.setFontSize(8);
    pdf.setFontSize(10);

    pdf.text(`Voucher No: ${selectedPurchaseRequest.voucher_no}`, 20, 30);
    pdf.text(`Activity: ${selectedPurchaseRequest.activity}`, 20, 35);
    pdf.text(`Profit Center: ${selectedPurchaseRequest.profit_center}`, 20, 40);
    pdf.text(`Covered Date: ${formatDate(selectedPurchaseRequest.covered_from)} - ${formatDate(selectedPurchaseRequest.covered_to)}`, 20, 45);
    pdf.text(`Status: ${selectedPurchaseRequest.status}`, 20, 50);

    const dateRequestedText = `Date Requested: ${formatDate(selectedPurchaseRequest.date_requested)}`;
    const dateRequestedWidth = pdf.getTextDimensions(dateRequestedText).w;
    const dateRequestedX = pdf.internal.pageSize.width - 20 - dateRequestedWidth;
    pdf.text(dateRequestedText, dateRequestedX, 30);

    const items = selectedPurchaseRequest.items;
    const headers = ['Description', 'Quantity', 'Unit of Measure', 'Price', 'Amount'];
    const data = items.map(item => [item.descriptions, item.quantity, item.uom, formatAmountWithCommas(item.price), formatAmountWithCommas(item.item_total_amount)]);
    const totalAmountRow = ['', '', '', 'Total:', `${formatAmountWithCommas(selectedPurchaseRequest.amount)}`];
    data.push(totalAmountRow);

    let startY = 55;

    pdf.autoTable({
      head: [headers],
      body: data,
      startY: startY,
      styles: {
        fontSize: 8
      },
      didDrawPage: function (data) {
        startY = data.cursor.y + 10;
      }
    });

    pdf.text(`Prepared By:`, 20, startY);
    pdf.text(`Date:`, 20, startY + 15);
    pdf.text(`${selectedPurchaseRequest.requested_by} `, 20, startY + 5);
    pdf.text(`Cost Controller`, 20, startY + 10);
    pdf.text(`${formatDate(selectedPurchaseRequest.encoded_date)} `, 30, startY + 15);

    pdf.text(`Released By:`, 50, startY + 20);
    pdf.text(`Date:`, 50, startY + 35);
    pdf.text(`${selectedPurchaseRequest.release}  ${selectedPurchaseRequest.release_last}`, 50, startY + 25);
    pdf.text(`Fund Custodian`, 50, startY + 30);
    pdf.text(`${formatDate(selectedPurchaseRequest.released_date)} `, 60, startY + 35);

    pdf.text(`Received By:`, 120, startY + 20);
    pdf.text(`Date:`, 120, startY + 30);
    pdf.text(`${selectedPurchaseRequest.received_by} `, 120, startY + 25);
    pdf.text(`${formatDate(selectedPurchaseRequest.received_date)} `, 130, startY + 30);

    pdf.text(`Reviewed By:`, 90, startY);
    pdf.text(`Date:`, 90, startY + 15);
    pdf.text(`${selectedPurchaseRequest.reviewer_name}  ${selectedPurchaseRequest.reviewer_last}`, 90, startY + 5);
    pdf.text(`Management Accounting Head`, 90, startY + 10);
    pdf.text(`${formatDate(selectedPurchaseRequest.reviewed_date)} `, 100, startY + 15);

    pdf.text(`Approved By:`, 150, startY);
    pdf.text(`Date:`, 150, startY + 15);
    pdf.text(`${selectedPurchaseRequest.approved}  ${selectedPurchaseRequest.approved_last}`, 150, startY + 5);
    pdf.text(`COO/GM`, 150, startY + 10);
    pdf.text(`${formatDate(selectedPurchaseRequest.approved_date)} `, 160, startY + 15);

    pdf.setFontSize(8);
    pdf.output('dataurlnewwindow');
  };

  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  return (
    <div>

      <div style={formContainerStyle}>
        <h4 style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label htmlFor="RemainingFund">List of Liquidated Fund Request</label>
          <select
            value={selectedDateFilter}
            onChange={handleDateFilterChange}
            style={{
              marginLeft: 'auto',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              cursor: 'pointer',
            }}
          >
            {dateOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </h4>
        <div style={{ background: '#EFF3F4' }}>
          {currentItems
            .slice()
            .sort((a, b) => a.voucher_no.localeCompare(b.voucher_no, 'en', { numeric: true }))
            .map((purchaseRequest) => (
              <div
                key={purchaseRequest.id}
                style={{
                  marginBottom: '16px',
                  padding: '8px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  position: 'relative',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <strong style={{ marginRight: '10px' }}>Voucher No:</strong>
                  <span style={{ marginRight: '10px' }}>{purchaseRequest.voucher_no}</span>
                  <strong style={{ marginRight: '10px' }}>Date Requested:</strong>
                  <span style={{ marginRight: '10px' }}> {formatDate(purchaseRequest.date_requested)}</span>
                  <strong style={{ marginRight: '10px' }}>Company Name:</strong>
                  <span style={{ marginRight: '10px' }}>   {purchaseRequest.business_unit_name}</span>
                </div>
                <button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '8px',
                    transform: 'translateY(-50%)',
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: '#52ab98',
                    color: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #52ab98',
                  }}
                  onClick={() => handleViewDetail(purchaseRequest)}
                >
                  View Detail
                </button>
              </div>
            ))}
        </div>
      </div>

      {selectedPurchaseRequest && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '70%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginRight: '5px' }}>
              <IconButton
                color="gray"
                onClick={generatePDF}
                sx={{ borderRadius: '4px', padding: '8px' }}
              >
                <PrintIcon />
              </IconButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>Voucher No:</strong> {selectedPurchaseRequest.voucher_no}
              </div>
              <div>
                <strong>Date Requested:</strong> {formatDate(selectedPurchaseRequest.date_requested)}
              </div>
            </div>

            <div>
              <strong>Covered Date:</strong> {formatDate(selectedPurchaseRequest.covered_from)} to {formatDate(selectedPurchaseRequest.covered_to)}
            </div>
            <div>
              <strong>Activity:</strong> {selectedPurchaseRequest.activity}
            </div>
            <div>
              <strong>Profit Center:</strong> {selectedPurchaseRequest.profit_center}
            </div>
            <div>
              <strong>Company Name:</strong> {selectedPurchaseRequest.business_unit_name}
            </div>
            <div>
              <strong>Status:</strong> {selectedPurchaseRequest.status}
            </div>
            <div>
              <strong>Items:</strong>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit of Measure</th>
                  <th>Price</th>
                  <th>Amount</th>

                </tr>
              </thead>
              <tbody>
                {selectedPurchaseRequest.items.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center' }}>{item.descriptions}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.quantity)}</td>
                    <td style={{ textAlign: 'center' }}>{item.uom}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.price)}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.item_total_amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div>
                {selectedPurchaseRequest.with_receipt != null && (
                  <div>
                    <strong>With Receipt:</strong> {formatAmountWithCommas(selectedPurchaseRequest.with_receipt)}
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div>
                {selectedPurchaseRequest.with_out_receipt != null && (
                  <div>
                    <strong>Without Receipt:</strong> {formatAmountWithCommas(selectedPurchaseRequest.with_out_receipt)}
                  </div>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div>
                {selectedPurchaseRequest.excess !== "0" && selectedPurchaseRequest.excess !== null && (
                  <div>
                    <strong>Excess:</strong> <u>{formatAmountWithCommas(selectedPurchaseRequest.excess)}</u>
                  </div>
                )}
                {selectedPurchaseRequest.refund !== "0" && selectedPurchaseRequest.refund !== null && (
                  <div>
                    <strong>Refund:</strong> <u>{formatAmountWithCommas(selectedPurchaseRequest.refund)}</u>
                  </div>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
              <div>
                <strong>Total Amount:</strong> <u>{formatAmountWithCommas(selectedPurchaseRequest.amount)}</u>
              </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {selectedPurchaseRequest.requested_by ? (
                <div><strong>Prepared By:</strong> {selectedPurchaseRequest.requested_by} </div>
              ) : (
                <div> <strong> Prepared By:</strong> {selectedPurchaseRequest.reviewer_name} {selectedPurchaseRequest.reviewer_last}</div>
              )}
              <div><strong>Reviewed By:</strong> {selectedPurchaseRequest.reviewer_name} {selectedPurchaseRequest.reviewer_last}</div>
              <div><strong>Approved By:</strong> {selectedPurchaseRequest.approved} {selectedPurchaseRequest.approved_last}</div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div><strong>Cost Controller</strong></div>
              <div><strong>Management Accounting Head</strong></div>
              <div><strong>COO/GM</strong></div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.encoded_date)} </div>
              <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.reviewed_date)} </div>
              <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.approved_date)} </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '600px', width: '100%' }}>
                <div><strong>Released By:</strong> {selectedPurchaseRequest.release} {selectedPurchaseRequest.release_last}</div>
                <div style={{ marginLeft: 'auto' }}><strong>Received By:  {selectedPurchaseRequest.received_by}</strong></div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '600px', width: '100%' }}>
                <div><strong>Fund Manager</strong></div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '600px', width: '100%' }}>
                <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.released_date)}</div>
                <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.received_date)}  </div>
              </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
              <button style={{ background: '#4CAF50', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleReplenish}>Replenish</button>
              <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClosePopup}>Close</button>
            </div>
          </div>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => paginate(number)}
            style={{
              backgroundColor: currentPage === number ? '#9FA6B2' : '#9FA6B280',
              color: 'white',
              border: 'none',
              padding: '5px 7px',
              cursor: 'pointer',
              borderRadius: '4px',
              marginRight: '5px',
            }}>
            {number}
          </button>
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
        <button
          style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }}
          onClick={navigateToDashboard}
        >
          Back
        </button>
      </div>

    </div>
  );
};


export default LiquidatedCard;
